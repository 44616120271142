export const collectionTypeFlavorTextMap = (key: string) => {
  const textMap: { [key: string]: string } = {
    criteo_retail_media: 'The ability to collect advertising data from Criteo Retail Media',
    demand_side_platform: 'Advertising data for Amazon product placements served outside of Amazon\'s retail site',
    home_depot_advertising: 'Search and banner advertising data for ads displayed on Home Depot\'s retail site',
    home_depot_pos: 'Sales analytics data from Home Depot\'s vendor to retailer sales platform',
    lowes_pos: 'Sales analytics data from Lowes\' vendor to retailer sales platform',
    meijer_pos: 'Sales analytics data from Meijer\'s vendor to retailer sales platform',
    product_page: 'Includes retailer pricing, star rating, digital content, etc. for items being tracked',
    product_seller_info: 'Obtains seller pricing information for all sellers of tracked items',
    product_reviews: 'Includes retailer\'s individual product reviews, their associated ratings, and contents',
    questions_answers: 'Provides information about posted consumer questions and answers with regards to products',
    retailer_search_result: 'Provides access to Share of Voice collection and analytics',
    seller_central_pos: 'Sales analytics data from Amazon\'s marketplace seller to retailer sales platform - Seller Central',
    search_advertising: 'Search and banner advertising data for ads displayed on Amazon\'s retail sites',
    omnichannel_advertising: 'Views into Omnichannel Advertising data',
    target_pos: 'Sales analytics data from Target\'s vendor to retailer sales platform',
    vendor_central_pos: 'Sales analytics data from Amazon\'s vendor to retailer sales platform - Vendor Central',
    walmart_connect_advertising: 'The ability to collect advertising data from Walmart Connect Advertising',
    walmart_luminate_pos: 'Sales analytics data from Walmart\'s 1P Luminate platform',
    walmart_pos: 'Sales analytics data from Walmart\'s 3P Seller Center platform',
    wayfair_pos: 'Sales analytics data from Wayfair\'s vendor to retailer sales platform',
    wayfair_search_advertising: 'Search and banner advertising data for ads displayed on Wayfair\'s retail sites',
  };
  if (key in textMap) {
    return textMap[key];
  }
  return `No Flavor Text for ${key}`;
};

export const collectionTypeFriendlyMap = (key: string) => {
  const textMap: { [key: string]: string } = {
    criteo_retail_media: 'Criteo Retail Media',
    demand_side_platform: 'Amazon Demand Side Platform',
    home_depot_advertising: 'Home Depot Advertising',
    home_depot_pos: 'Home Depot POS',
    lowes_pos: 'Lowes POS',
    meijer_pos: 'Meijer POS',
    product_page: 'Product Page',
    product_seller_info: 'Product Seller Information',
    product_reviews: 'Product Reviews',
    questions_answers: 'Questions and Answers',
    retailer_search_result: 'Share of Voice',
    search_advertising: 'Amazon Search Advertising',
    seller_central_pos: 'Amazon Seller Central POS',
    omnichannel_advertising: 'Omnichannel Advertising',
    target_pos: 'Target POS',
    vendor_central_pos: 'Amazon Vendor Central POS',
    walmart_connect_advertising: 'Walmart Connect Advertising',
    walmart_luminate_pos: 'Walmart 1P POS',
    walmart_pos: 'Walmart 3P POS',
    wayfair_pos: 'Wayfair POS',
    wayfair_search_advertising: 'Wayfair Search Advertising',
  };
  if (key in textMap) {
    return textMap[key];
  }
  return key;
};

export default collectionTypeFlavorTextMap;
