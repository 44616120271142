import { CollectionType } from '../../controllers/subscription-service/types';

/* eslint-disable max-len */
export type NotificationTemplateType = {
  label: string,
  template: string,
};

const messageTemplates: NotificationTemplateType[] = [
  {
    label: 'Retailer Site Change',
    template: 'Due to <retailer_name> changing their site structure, <collection_type> data is delayed.',
  },
  {
    label: 'Retailer Significant Site Change',
    template: 'Due to <retailer_name> significantly changing their site structure, <collection_type> data is delayed.',
  },
  {
    label: 'Retailer Site Outage',
    template: 'Due to <retailer_name> being offline, data is delayed.',
  },
  {
    label: 'Retailer Report Period Unavailable',
    template: '<retailer_name> report source data is not available. <feature_name> data appearing in our dashboards is behind as a result.',
  },
  {
    label: 'Retailer Report Data Unreliable',
    template: 'Material anomalies in <retailer_name> report source data have been detected. Data in our dashboards may be inaccurate as a result.',
  },
  {
    label: 'QuickSight Issue',
    template: 'Issues with backend services have resulted in the temporary outage of <feature_name> dashboards.',
  },
];

const collectionTypeList: { type: CollectionType, english: string }[] = [
  {
    type: 'criteo_retail_media',
    english: 'Criteo Retail Media',
  },
  {
    type: 'demand_side_platform',
    english: 'Demand Side Platform',
  },
  {
    type: 'meijer_pos',
    english: 'Meijer POS',
  },
  {
    type: 'product_page',
    english: 'Product Page',
  },
  {
    type: 'product_reviews',
    english: 'Product Reviews',
  },
  {
    type: 'product_seller_info',
    english: 'Product Seller Information',
  },
  {
    type: 'questions_answers',
    english: 'Questions and Answers',
  },
  {
    type: 'retailer_search_result',
    english: 'Retailer Search Result',
  },
  {
    type: 'search_advertising',
    english: 'Search Advertising',
  },
  {
    type: 'seller_central_pos',
    english: 'Seller Central POS',
  },
  {
    type: 'vendor_central_pos',
    english: 'Vendor Central POS',
  },
  {
    type: 'home_depot_advertising',
    english: 'Home Depot Advertising',
  },
  {
    type: 'home_depot_pos',
    english: 'Home Depot POS',
  },
  {
    type: 'lowes_pos',
    english: 'Lowes POS',
  },
  {
    type: 'omnichannel_advertising',
    english: 'Omnichannel Advertising',
  },
  {
    type: 'walmart_connect_advertising',
    english: 'Walmart Connect Advertising',
  },
  {
    type: 'walmart_luminate_pos',
    english: 'Walmart Luminate POS',
  },
  {
    type: 'walmart_pos',
    english: 'Walmart POS',
  },
  {
    type: 'wayfair_pos',
    english: 'Wayfair POS',
  },
  {
    type: 'wayfair_search_advertising',
    english: 'Wayfair Search Advertising',
  },
];

const collectionTypeToEnglishMap = (k: CollectionType) => (
  collectionTypeList.filter(({ type }) => type === k).length
    ? collectionTypeList.filter(({ type }) => type === k)[0].english
    : k
);

const englishToCollectionTypeMap = (k: string) => (
  collectionTypeList.filter(({ english }) => english === k).length
    ? collectionTypeList.filter(({ english }) => english === k)[0].type
    : k
);

export {
  messageTemplates,
  collectionTypeToEnglishMap,
  englishToCollectionTypeMap,
};
